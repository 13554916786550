import _cloneDeep from 'clone-deep';
import CONFIG from 'config/configProps';
import { isValid, parseISO, format } from 'date-fns';
import moment from 'moment';
import { isEmptyOrNull } from 'utils';
/**
 * Deep clone the given object or array
 *
 * @param {Object|Array}  obj
 * @returns
 */
export const cloneDeep = obj => _cloneDeep(obj);

/**
 * Checks whether the given value is empty
 *
 * @param   {*}       value
 * @returns {Boolean}
 *
 */
export const isEmpty = value => {
  const has = Object.prototype.hasOwnProperty;

  // Null and Undefined...
  if (value == null) return true;

  const type = typeof value;

  // Booleans...
  if ('boolean' === type) return false;

  // Numbers...
  if ('number' === type) return value === 0;

  // Strings and arrays
  if ('string' === type || Array.isArray(value)) return value.length === 0;

  // Objects...
  if ('object' === type) {
    if (!Object.keys(value).length) return true;

    for (const key in value) {
      if (has.call(value, key)) return false;
    }

    return true;
  }

  // Anything else...
  return false;
};

/**
 * Checks whether given two values are same or not
 *
 * @param   {*} value1
 * @param   {*} value2
 * @returns {Boolean}
 */
export const isEqual = (value1, value2) => {
  if (
    value1 === null ||
    value1 === undefined ||
    value2 === null ||
    value2 === undefined
  ) {
    return value1 === value2;
  }
  // after this just checking type of one would be enough
  if (value1.constructor !== value2.constructor) {
    return false;
  }
  // if they are functions, they should exactly refer to same one (because of closures)
  if (value1 instanceof Function) {
    return value1 === value2;
  }
  // if they are regexps, they should exactly refer to same one (it is hard to better equality check on current ES)
  if (value1 instanceof RegExp) {
    return value1 === value2;
  }
  if (value1 === value2 || value1.valueOf() === value2.valueOf()) {
    return true;
  }
  if (Array.isArray(value1) && value1.length !== value2.length) {
    return false;
  }

  // if they are dates, they must had equal valueOf
  if (value1 instanceof Date) {
    return false;
  }
  // if they are strictly equal, they both need to be object at least
  if (!(value1 instanceof Object)) {
    return false;
  }
  if (!(value2 instanceof Object)) {
    return false;
  }

  // recursive object equality check
  const p = Object.keys(value1);
  return (
    Object.keys(value2).every(index => {
      return p.indexOf(index) !== -1;
    }) &&
    p.every(index => {
      return isEqual(value1[index], value2[index]);
    })
  );
};

/**
 *
 * @param   {*} value
 * @returns {Boolean}
 */
export const isArray = value => {
  return !!value && Array.isArray(value);
};

/**
 * Check is it an Object
 *
 * @param   {*}       value
 * @returns {boolean}
 */
export const isObject = (value, strict = true) => {
  const type = typeof value;

  if (!value) {
    return false;
  }

  return (
    !isArray(value) && (type === 'object' || (!strict && type === 'function'))
  );
};

/**
 *
 * @param {function} fn - function to be called after the delay
 * @param {Number} ms   - delay in miliseconds
 * @returns
 */
export const debounce = (fn, ms = 0) => {
  let timeoutId = null;
  return (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};

/**
 *
 * @param   {Number}   count - counter to repeat the function call
 * @param   {function} fn    - function to be called
 * @returns {Array}          - result from of function stored in array
 */
export const times = (count = 0, fn = () => null) => {
  const result = [];
  while (count > 0) {
    count--;
    result.push(fn(count));
  }

  return result;
};

/**
 *
 * @param   {Object[]} values - Array of objects
 * @param   {String}   key    - key to compare array objects
 * @returns {object[]}        - array of unique objects based on object key
 */
export const uniqueByKey = (values, key) => {
  return Object.values(
    values.reduce((acc, obj) => ({ ...acc, [obj[key]]: obj }), {})
  );
};

/**
 *
 * @param   {Object[]} firstArray    - Array of object to check
 * @param   {Object[]} secondArray   - Array of Object to exclude
 * @param   {String}   key           - key to compare array objects
 * @returns {Object[]}               - Array of objects of firstArray excluding the objects in second array
 */
const arrayDiffByKey = (firstArray, secondArray, key) => {
  return firstArray.filter(
    element => !secondArray.some(item => item[key] === element[key])
  );
};

/**
 *
 * @param    {...Object[]} arrays - Arrays of objects to compare
 * @param    {String}      key    - key to compare the objects in array
 * @returns  {Object[]}           - Array of objects which are not repeated in any of the arrays
 *
 * @example
 *  const a = [{k:1}, {k:2}, {k:3}];
 *  const b = [{k:1}, {k:4}, {k:5}, {k:6}];
 *  const c = [{k:3}, {k:5}, {k:7}];
 * Result: [{"k":2},{"k":4},{"k":6},{"k":7}]
 */
export const xorBy = (...arrays) => {
  const key = arrays[arrays.length - 1];
  if (typeof key !== 'string') {
    return [].concat(...arrays);
  }
  arrays.pop();

  return [].concat(
    ...arrays.map((arr, i) => {
      const others = arrays.slice(0);
      others.splice(i, 1);
      const unique = uniqueByKey([].concat(...others), key);
      return arrayDiffByKey(arr, unique, key);
    })
  );
};

/**
 *
 * @param   {String} dateStr   - Date string in ISO fromat
 * @param   {String} formatStr - Default is "MM-dd-yyyy" format. Refer date-fns for fromatting options
 * @returns {String}           - Formatted date string
 */
export const formatDate = (dateStr, formatStr = 'MM-dd-yyyy') => {
  if (!dateStr) return '';

  const dateObj = parseISO(dateStr);
  if (isValid(dateObj)) {
    return format(dateObj, formatStr);
  }

  return '';
};

/**
 *
 * @param   {Object} file
 * @returns {Object}
 */
export const validateFileZise = file => {
  if (file.size > CONFIG.MAX_FILE_SIZE_IN_BYTES) {
    return {
      code: 'file-size-limit-exceeded',
      message: `File too large! Maximum upload size exceeded`,
    };
  }

  return null;
};

/**
 *
 * @param   {Number} bytes
 * @param   {Number} decimalPoints
 * @returns {String}
 */
export const formatFileSize = (bytes, decimalPoints = 2) => {
  const units = ['B', 'KB', 'MB', 'GB', 'TB'];
  let i;

  for (i = 0; bytes >= 1024 && i < 4; i++) {
    bytes /= 1024;
  }

  return bytes.toFixed(decimalPoints) + units[i];
};

/**
 *
 * @param {Object} event
 */
export const preventDefault = event => {
  event && event.preventDefault();
};

/**
 *
 * @param {Object} data     - binary file data
 * @param {String} filename
 * @param {string} type     - file mime type
 */
export const downloadFile = (data, filename, type) => {
  const binaryData = [data];
  const url = window.URL.createObjectURL(new Blob(binaryData, { type }));
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

/**
 *
 * @param   {String} url - url string to sanitize
 * @returns {String}     - return the url sring if it matches safe pattern
 */
export const sanitizeUrl = url => {
  url = url.trim();

  /** A pattern that matches safe URLs. matches http, https, mailto, ftp, tel, file, sms */
  const SAFE_URL_PATTERN =
    /^(?:(?:https?|mailto|ftp|tel|file|sms):|[^&:/?#]*(?:[/?#]|$))/gi;

  /** A pattern that matches safe data URLs. It only matches image, video, and audio types. */
  const DATA_URL_PATTERN =
    /^data:(?:image\/(?:bmp|gif|jpeg|jpg|png|tiff|webp)|video\/(?:mpeg|mp4|ogg|webm)|audio\/(?:mp3|oga|ogg|opus));base64,[a-z0-9+\\/]+=*$/i;

  if (!url || url === 'about:blank') return '';
  if (url.match(SAFE_URL_PATTERN) || url.match(DATA_URL_PATTERN)) return url;

  return '';
};

/**
 *
 * @param {Object}  props          - props object from component
 * @returns
 */

export const showSearch = list => {
  return list.length > 5;
};

/**
 *
 * @param {Object}  props          - props object from component
 * @returns
 */

export const showFilterDropDown = list => {
  return list.length > 1;
};

export const formatSelectedDate = (selectedDate, dateFormat) => {
  if (!selectedDate) {
    return '';
  }
  return Array.isArray(selectedDate)
    ? selectedDate.map(date => moment(date).format(dateFormat)).join('-')
    : moment(selectedDate).format(dateFormat);
};

export const isSingleDigit = val => {
  return /^\d$/.test(val);
};

/**
 * @param {Collection <Array, Object>} collection
 * @param {Function} callback
 * @returns
 */
export const forEach = (collection, iteratee) => {
  if (isArray(collection)) {
    for (let i = 0; i < collection.length; i++) {
      if (iteratee(collection[i], i, collection) === false) {
        break;
      }
    }
  } else if (isObject(collection)) {
    for (const key in collection) {
      // eslint-disable-next-line no-prototype-builtins
      if (collection.hasOwnProperty(key)) {
        if (iteratee(collection[key], key, collection) === false) {
          break;
        }
      }
    }
  }
};

/**
 * Runs Array.map function on the value, func provided
 * @param   {Array} value
 * @param   {Function} callback
 * @returns {Array}
 */
export const map = (collection, callback) => {
  if (Array.isArray(collection)) {
    const result = [];
    for (let i = 0; i < collection.length; i++) {
      result.push(callback(collection[i], i, collection));
    }
    return result;
  } else if (typeof collection === 'object') {
    const result = [];
    for (const key in collection) {
      // eslint-disable-next-line no-prototype-builtins
      if (collection.hasOwnProperty(key)) {
        result.push(callback(collection[key], key, collection));
      }
    }
    return result;
  }
  return [];
};

export const numberWithCommas = x => {
  if (isEqual(x, 0) || isEmptyOrNull(x)) return 0;
  return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
};

export function isLoading(obj) {
  return obj.isLoading || typeof obj.isLoading == 'undefined';
}

export function makeFirstLetterCap(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function disableFilterSelection(apiCallsInProgress) {
  if (isEmptyOrNull(apiCallsInProgress)) return true;
  let apiCallCompleted = false;

  for (let i in apiCallsInProgress) {
    if (apiCallsInProgress[i] === 1) {
      apiCallCompleted = true;
      break;
    }
  }
  return apiCallCompleted;
}
