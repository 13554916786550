import React, { useState, useRef, useEffect } from 'react';
import Button from 'components/common/button/Button';
import Icon from 'components/common/icon';
import DropDownFilter from 'components/common/AdminFilter/KtDdFilters';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'redux/actions';
import './index.scss';

const KTSortByButton = props => {
  const dropDownRef = useRef();
  const [openDropDown, setOpenDropDown] = useState(false);

  const selectedSortByOption = useSelector(state => state.common.sortByChoice);
  const dispatch = useDispatch();

  useEffect(() => {
    const checkIfClickedOutside = e => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (
        openDropDown &&
        dropDownRef.current &&
        !dropDownRef.current.contains(e.target)
      ) {
        setOpenDropDown(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [openDropDown]);

  const onFilterChange = (value, name) => {
    dispatch(
      actions.commonActions.saveSortByChoice({
        ...selectedSortByOption,
        [props.filterName]: { label: name, value: value },
      })
    );
  };
  return (
    <div className="sort-by-container" ref={dropDownRef}>
      <Button
        onClick={() => setOpenDropDown(!openDropDown)}
        className="sort-by-container__btn"
        icon={<Icon name="exchange" customClassName="rotate90" />}
        content="Sort by"
      />
      {openDropDown && (
        <DropDownFilter
          dataList={props.dataList}
          selectedList={selectedSortByOption?.[props.filterName]?.value || []}
          className="padding-10 charges_hours_filters sort-by-filters"
          onChange={onFilterChange}
          clearFilter={onFilterChange}
          singleSelect={true}
          showSearch={false}
          showRadioButton={true}
          clearButtonText={'Cancel'}
          stateField="sort"
          selectedName={selectedSortByOption?.[props.filterName]?.label || ''}
          sortingField={props.sortingField}
        />
      )}
    </div>
  );
};

export default KTSortByButton;
