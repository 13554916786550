import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import Helmet from 'react-helmet';
import Favicon from 'react-favicon';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import history from 'config/history';
import CONFIG from 'config/configProps';
import auth, { restoreOriginalUri } from 'config/auth';
import UnknownRoute from 'components/UnknownRoute/UnknownRoute';
import Unauthorized from 'components/UnknownRoute/Unauthorized';
import ProtoType from 'components/PrototypeDesign';
import 'resources/css/index.scss';

const env = CONFIG.ENV;
const App = () => {
  return (
    <Router history={history}>
      <Helmet>
        <script src={process.env.REACT_APP_ADOBE_URL} async={true} />
      </Helmet>
      <Favicon url={`/img/kt_favicon_${env}.png`} />
      <Security
        oktaAuth={auth}
        restoreOriginalUri={restoreOriginalUri(history)}
      >
        <Switch>
          <Route
            exact
            path={CONFIG.OKTA.REDIRECT_URL}
            component={LoginCallback}
          />
          <Route
            exact
            path={`${CONFIG.APP_CONST.UNAUTHORIZED}`}
            component={Unauthorized}
          />
          <SecureRoute
            path={`${CONFIG.PROTOTYPE_URLS.HOME}`}
            component={ProtoType}
          />
          <Route component={UnknownRoute} />
        </Switch>
      </Security>
    </Router>
  );
};

export default React.memo(App);
