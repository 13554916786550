import React, { useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { isEmptyOrNull } from 'utils';
import { isEmpty, isEqual, isLoading } from 'utils/common';
import KTHomeLoader from './KTHomeLoader';

const transformRawDataToChartSeries = (sectionCategories, props, colors) => {
  let totalValues = [];
  let monthlyTrendsData = props.isPulseData
    ? props.data.data.results.monthlyTrend
    : props.data.data.results.monthlyTrends;
  let sortedData = Object.entries(monthlyTrendsData);
  if (sortedData.length === 0) {
    return undefined;
  }
  const labels = Object.keys(monthlyTrendsData);
  let metricChartSeries = [];
  const stackObjs = [...labels.map(name => monthlyTrendsData[name])];
  const data = {};
  sectionCategories.forEach(stackKey => {
    data[stackKey] = [];
  });

  stackObjs.forEach(stackObjValues => {
    let totalValueForStackObj = 0;
    sectionCategories.forEach(stackKey => {
      const value = Math.round(stackObjValues[stackKey]);
      totalValueForStackObj += value;
      data[stackKey].push(value);
    });
    totalValues.push(totalValueForStackObj);
  });
  metricChartSeries = sectionCategories.map(key => ({
    name: key,
    data: data[key],
    color: colors[key],
  }));
  return {
    chartALabels: labels,
    metricChartSeries: props.actualOrder
      ? [...metricChartSeries]
      : [...metricChartSeries].reverse(),
    totalValues: totalValues,
    length: metricChartSeries.length,
  };
};

const KTBillabilityTrend = props => {
  const ref = useRef(null);
  const { colors, legends, data, isPulseData, section } = props;

  const sectionCategories = Object.keys(legends);
  const rawData = !isEmptyOrNull(data.data) ? data.data.results : {};
  const chartData =
    !isEmptyOrNull(rawData) &&
    !isEmpty(isPulseData ? rawData.monthlyTrend : rawData.monthlyTrends)
      ? transformRawDataToChartSeries(sectionCategories, props, colors)
      : {};
  const metricChartOptions =
    chartData &&
    getMetricChart(
      chartData.metricChartSeries,
      chartData.chartALabels,
      legends,
      isPulseData
    );
  const totalValues = !isEmptyOrNull(rawData)
    ? isPulseData
      ? rawData.aggregatedPercentage
      : rawData.allBillabilityTotal
    : {};
  return (
    <div
      style={{
        height: '95%',
      }}
    >
      {isLoading(data) ? (
        <KTHomeLoader
          isLoading={1}
          section={section}
          count={isPulseData ? 5 : 8}
          className="highchart_container_loader"
        />
      ) : isEmptyOrNull(
          isPulseData ? rawData.monthlyTrend : rawData.monthlyTrends
        ) ? (
        <div className="static-text">No data to display</div>
      ) : (
        <div className="highchart_container_chart">
          <div
            style={{
              height: '100%',
            }}
            ref={ref}
          >
            <HighchartsReact
              options={metricChartOptions}
              allowChartUpdate={false}
              highcharts={Highcharts}
              containerProps={{
                style: {
                  height: '100%',
                  position: 'relative',
                },
              }}
            />
          </div>
        </div>
      )}
      {isEmptyOrNull(totalValues) || isLoading(data) ? null : (
        <div className="highchart_container_bottom">
          <div className="metric_legends">
            {sectionCategories.map(
              key =>
                !isEqual(totalValues[key], 0) && (
                  <div key={key}>
                    <div className="total_values">{`${
                      totalValues?.[key] || 0
                    }%`}</div>
                    <div className="metric_legend_item">
                      <div
                        className="legend-icon"
                        style={{ backgroundColor: colors[key] }}
                      />
                      <div className="heading">{legends[key]}</div>
                    </div>
                  </div>
                )
            )}
          </div>
        </div>
      )}
    </div>
  );
};

function getMetricChart(metricSeries, labels, legends, isPulseData) {
  return {
    chart: {
      type: 'column',
      spacingLeft: 0,
      spacingRight: 0,
      scrollablePlotArea: {
        minWidth: 700,
        scrollPositionX: 1,
      },
    },
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    xAxis: {
      categories: labels,
      lineWidth: 0,
      labels: {
        rotation: 0,
      },
    },
    yAxis: {
      gridLineColor: 'ffffff',
      title: {
        text: '',
      },
      labels: {
        enabled: false,
      },
      stackLabels: {
        enabled: true,
        formatter: function () {
          return `${this.total}${isPulseData ? '' : '%'}`;
        },
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        pointWidth: 35,
        dataLabels: {
          enabled: true,
          formatter: function () {
            return `${
              this.y !== 0 ? `${this.y}${isPulseData ? '' : '%'}` : ''
            }`;
          },
        },
      },
      series: {
        stacking: 'percent',
        borderWidth: 0,
      },
    },
    tooltip: {
      formatter: function () {
        return `<span><b> ${legends[this.series.name]} : </b>${this.y}${
          isPulseData ? '' : '%'
        }</span>`;
      },
    },
    series: metricSeries,
  };
}

export default KTBillabilityTrend;
