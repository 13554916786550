import React from 'react';

const Radio = props => {
  const checkedColor = () => {
    if (props.checked) {
      return props.checkedColor ? props.checkedColor : '#212529';
    }

    return props.unCheckedColor;
  };

  return (
    <label
      className={props.className}
      style={{ position: 'relative', opacity: props.disabled ? 0.3 : 1 }}
    >
      <input
        type="radio"
        value={props.value}
        checked={props.checked}
        onChange={() => props.handleChange(props.name, props.value)}
        style={{ opacity: 0, margin: props?.inputMargin || '0 .5rem' }}
        name={props.name}
      />
      {props.showValue}
      <span
        style={{
          position: 'absolute',
          left: props?.roundLeft || '.7rem',
          top: '0.4rem',
          width: '1rem',
          height: '1rem',
          border: `.1rem solid ${props.bgColor ? props.bgColor : '#212956'}`,
          borderRadius: '50%',
        }}
      />
      <span
        style={{
          position: 'absolute',
          left: props?.circleLeft || '.9rem',
          top: '.6rem',
          width: '.6rem',
          height: '.6rem',
          background: checkedColor(),
          borderRadius: '50%',
          zIndex: 1,
        }}
      />
    </label>
  );
};

export default Radio;
