import { SAVE_SORT_BY_CHOICE } from 'redux/actions/actionTypes';
import InitialState from './InitialState';

const STATE_SLICE_NAME = 'commonActions';

const commonReducer = function (
  state = InitialState[STATE_SLICE_NAME],
  action = ''
) {
  switch (action.type) {
    case SAVE_SORT_BY_CHOICE:
      return {
        ...state,
        sortByChoice: action.sortByChoice,
      };

    default:
      return state;
  }
};
export default commonReducer;
