/* eslint-disable func-names */
import { SAVE_SORT_BY_CHOICE } from './actionTypes';

const saveSortByChoice = sortByChoice => {
  return {
    type: SAVE_SORT_BY_CHOICE,
    sortByChoice,
  };
};

export const commonActions = {
  saveSortByChoice,
};
