import React, { useState } from 'react';
import CONFIG from 'config/configProps';
import { Table } from 'semantic-ui-react';
import { isEqual } from 'utils/common';
import KTSearch from 'components/KTSearch';
import './index.scss';
import { isEmptyOrNull } from 'utils';
import { useDispatch } from 'react-redux';
import actions from 'redux/actions';

const KtAdminTable = props => {
  const dispatch = useDispatch();
  let data = props.adminUserList?.data?.results || {};
  const sortedData = Object.keys(data).sort();
  const [isChecked, setIsChecked] = useState(false);
  const [apiData, setApiData] = useState(sortedData);
  const [showEditPopUp, setShowEditPopUp] = useState(
    !isEmptyOrNull(props.selectedUserByAdmin)
      ? { [props.selectedUserByAdmin?.employeeIdHRO]: true }
      : {}
  );
  const isMissing = val => isEqual(val, 'Unspecified');
  const filterMissingData = checked => {
    let filteredData = { ...data };
    if (checked) {
      filteredData = Object.fromEntries(
        Object.entries(data)
          .map(([key, value]) => {
            // Filter the array if it contains objects with subSectorName or sectorName 'Unspecified'
            const filteredItems = value.filter(
              item =>
                item.subSectorName === 'Unspecified' ||
                item.sectorName === 'Unspecified'
            );
            // Only keep the key if there are items in the array after filtering
            return filteredItems.length > 0 ? [key, filteredItems] : null;
          })
          .filter(item => item !== null) // Remove null values
      );
    }
    const sortedData = Object.keys(filteredData).sort();
    setApiData(sortedData);
  };

  const renderTable = () => {
    let headerColumns = CONFIG.ADMIN_TABLE_COLUMNS;

    return (
      <Table
        basic
        celled
        sortable
        compact
        id="spark-line-table"
        className="kt-table-container__chart__table"
      >
        <Table.Header className="kt-table-container__chart__table__header">
          <Table.Row>
            {headerColumns.map(col => {
              return (
                <Table.HeaderCell
                  key={col}
                  className={`${
                    isEqual(col, 'Sub-Topic/Segment') ? 'borderRightNone' : ''
                  }`}
                >
                  {col}
                </Table.HeaderCell>
              );
            })}
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body className="kt-table-container__chart__table__body">
          {apiData.map(user => {
            let count = 0;
            return (
              <>
                <Table.Row
                  key={user}
                  className="kt-table-container__chart__table__body_main"
                >
                  <Table.Cell
                    className="employee_name details"
                    rowSpan={data[user].length + 1}
                  >
                    {user}
                  </Table.Cell>
                  <Table.Cell
                    className="employee_role details"
                    rowSpan={data[user].length + 1}
                  >
                    {data[user][0]['biggestRole']}
                  </Table.Cell>
                </Table.Row>
                {data[user].map(el => {
                  count = count + 1;
                  return (
                    <Table.Row>
                      <Table.Cell className="employee_pa details">
                        {isEqual(el.managementModelTransform, 'Geo Managed')
                          ? el.geoBusinessTransform
                          : el.practiceAreaName}
                      </Table.Cell>
                      <Table.Cell
                        className={`${
                          isMissing(el.sectorName) ? 'missing' : ''
                        } employee_sector details`}
                      >
                        {isMissing(el.sectorName) ? (
                          <div>
                            <img
                              src="/img/red-info.png"
                              alt="information"
                              className="kt-info"
                            />
                            <span>Missing</span>
                          </div>
                        ) : (
                          el.sectorName
                        )}
                      </Table.Cell>
                      <Table.Cell
                        className={`${
                          isMissing(el.subSectorName) ? 'missing' : ''
                        } employee_sub_sector details`}
                      >
                        {isMissing(el.subSectorName) ? (
                          <div>
                            <img
                              src="/img/red-info.png"
                              alt="information"
                              className="kt-info"
                            />
                            <span>Missing</span>
                          </div>
                        ) : (
                          el.subSectorName
                        )}
                      </Table.Cell>
                      {isEqual(count, 1) && (
                        <Table.Cell
                          className="edit_icon details"
                          rowSpan={data[user].length}
                          onClick={() => {
                            dispatch(
                              actions.adminPageActions.updateSelectedUserByAdmin(
                                data[user][0]
                              )
                            );
                            dispatch(
                              actions.adminPageActions.fetchSelectedUserRolesList(
                                el.employeeIdHRO
                              )
                            );
                            setShowEditPopUp({
                              [el.employeeIdHRO]:
                                !showEditPopUp[el.employeeIdHRO],
                            });
                          }}
                        >
                          ...
                        </Table.Cell>
                      )}
                      {isEqual(count, 1) && renderEditPopUp(el.employeeIdHRO)}
                    </Table.Row>
                  );
                })}
              </>
            );
          })}
        </Table.Body>
      </Table>
    );
  };

  const renderEditPopUp = employeeIdHRO => {
    return (
      showEditPopUp[employeeIdHRO] && (
        <div
          className="edit"
          onClick={e => {
            e.stopPropagation();
            props.showUserDetails();
          }}
        >
          Edit
        </div>
      )
    );
  };
  return (
    <>
      <div className="top_section">
        <div className="admin-search-container">
          <KTSearch
            placeholder="Search All Members"
            fromAdminListing={true}
            className="admin-search"
          />
        </div>
        <div className="missing-switch">
          <label className="switch">
            <input
              type="checkbox"
              checked={isChecked}
              onClick={() => {
                setIsChecked(!isChecked);
                filterMissingData(!isChecked);
              }}
            />
            <span className="slider round" />
          </label>
          <div className="missing_details">Missing Details</div>
        </div>
      </div>
      {renderTable()}
    </>
  );
};
export default KtAdminTable;
