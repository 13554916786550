import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CONFIG from 'config/configProps';
import { isEmpty } from 'utils/common';
import { Grid } from 'semantic-ui-react';
import ImpersonatorList from 'components/common/KtImpersonator';
import LabelWithSkeleton from 'components/common/KtLabelWithSkeleton';
import './KTHeader.scss';

const KTHeader = () => {
  const impersonatorRef = useRef();
  const history = useHistory();

  const userDetails = useSelector(
    state => state.homePageData.fetchUserDetails?.results || {}
  );

  const lastUpdatedDate = useSelector(
    state => state.filterDataPt.lastUpdatedDate || ''
  );

  const [selectedText, setSelectedText] = useState('Select');
  const [showMembers, setShowMembers] = useState(false);

  useEffect(() => {
    const checkIfClickedOutside = e => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (
        showMembers &&
        impersonatorRef.current &&
        !impersonatorRef.current.contains(e.target)
      ) {
        setShowMembers(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [showMembers]);

  const goToHome = () => {
    history.push({
      pathname: CONFIG.PROTOTYPE_URLS.HOME,
    });
  };

  const applyView = memberDetails => {
    setSelectedText(memberDetails?.employeeName || 'Select');
    setShowMembers(false);
  };
  return (
    <Grid className="Kt_dashboard_header">
      <Grid.Column width={2} className="marginR50">
        <img
          src="/img/BCG_Vantage_Logo.png"
          alt="BCG Vanatage logo"
          className="Kt_dashboard_header_logo"
          onClick={goToHome}
        />
      </Grid.Column>
      <Grid.Column width={3} floated="right" className="rightAlign displayFlex">
        {userDetails?.impersonator && (
          <div className="impersonator-container" ref={impersonatorRef}>
            <p className="impersonator">User Validation</p>
            <p
              onClick={() => setShowMembers(!showMembers)}
              className="select-impersonator"
            >
              <span className="text">{selectedText}</span>
              <img
                src="../img/kt-scorecard-white-arrow.svg"
                alt="arrow-icon"
                className={'kt-drop-arrow'}
              />
            </p>
            {showMembers && <ImpersonatorList applyView={applyView} />}
          </div>
        )}
        <img
          className="Kt_dashboard_header_timeStamp"
          src="../img/timestamp.png"
          alt="timestamp"
        />
        <div className="timeperiod">
          {isEmpty(lastUpdatedDate) ? (
            <LabelWithSkeleton isLoading size="small" showLine />
          ) : (
            `Data as of ${lastUpdatedDate}`
          )}
        </div>
      </Grid.Column>
    </Grid>
  );
};

export default KTHeader;
