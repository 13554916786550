import React, { useEffect } from 'react';
import CONFIG from 'config/configProps';
import { Grid } from 'semantic-ui-react';
import { isEmpty, isLoading, isEqual } from 'utils/common';
import LabelWithSkeleton from 'components/common/KtLabelWithSkeleton';
import { globalAdobeAnalytics } from 'config/analytics';
import './KTHome.scss';
import {
  isAnalyst,
  isEmptyOrNull,
  isFetchRequired,
  getPostDataForBusinessView,
  getDefaultFilters,
  isSuperAdminRoleNew,
  isKtLineManger,
} from 'utils';
import KTTeamSize from './TeamSize';
import KTTimesheet from './Timesheet';
import KTTopDownloads from './TopDownloads';
import KTEngagementDepth from './EngagmentDepth';
import KTTopClients from './TopClients';
import KTBillabilityTrend from './KTBillabilityTrends';
import KTPulse from './KTPulse';
import KTAnalystFilters from 'components/KTAnalystFilters/KTAnalystFilters';
import KTBillability from './KTBillability';
import KTHourlyBillRate from './KTHourlyBillRate';
import KTRms from './KTRms';
import KnMaterials from './KnMaterial';

const KTHome = props => {
  const {
    actions,
    appliedFilters,
    billabilityTrendsDataMember,
    billabilityTrendsDataBusiness,
    timeSheetDataMember,
    timeSheetDataBusiness,
    topDownloadsDataMember,
    topDownloadsDataBusiness,
    topClientsDataMember,
    topClientsDataBusiness,
    ktPulseCheckData,
    engagementDepthDataMember,
    engagementDepthDataBusiness,
    teamSizeData,
    ktBillabilityData,
    hourlyBillRateData,
    cdBillabilityDataMember,
    cdBillabilityDataBusiness,
    topProjectsDataBusiness,
    topProjectsDataMember,
    userDetails,
    selectedView,
    selectedUserByImpersonator,
    isDataReset,
    rmsSatisfactionDataMember,
    rmsSatisfactionDataBusiness,
    adobeData,
    loggedInUserRole,
    loggedInUserId,
    knMaterialDataMember,
    knMaterialDataBusiness,
    appliedCollection,
  } = props;
  const activeIndex = 0;
  const userData = userDetails.data;
  const isAnalystUser = isAnalyst(loggedInUserRole);
  const isLineManager = isKtLineManger(loggedInUserRole);
  const isMemberViewOpen = isAnalystUser && isEqual(activeIndex, 0);
  const isBusinessViewOpen = !isMemberViewOpen;

  useEffect(() => {
    getApiData();
  }, [appliedFilters, userDetails, selectedView]);

  useEffect(() => {
    if (!adobeData?.homePage) {
      const isScriptLoaded = globalAdobeAnalytics(`KTDashboard-HomePage`);
      isScriptLoaded && updateAdobeData();
    }
  }, [adobeData]);

  const updateAdobeData = () => {
    if (!adobeData?.homePage) actions.updateAdobeDetails({ homePage: true });
  };

  const getApiData = (
    selectedKtQustion,
    selectedBillQuestion,
    selectedTopProjectsQuestion
  ) => {
    let dataToPost = {};
    if (isEmptyOrNull(appliedFilters) && !isAnalystUser) return;
    else if (isEmptyOrNull(appliedFilters) && isAnalystUser) {
      dataToPost = getDefaultFilters(loggedInUserId);
      if (
        isEmptyOrNull(props.practiceArea?.data) &&
        isEmptyOrNull(props.geoMarket?.data) &&
        !isLineManager
      ) {
        actions.fetchFiltersData(true, true, false, false, false, dataToPost);
      }
    } else {
      const { startDate, endDate, dateFilterType } = appliedFilters;
      dataToPost = {
        startDate: startDate,
        endDate: endDate,
        dateFilterType:
          !isEmpty(dateFilterType) || appliedFilters?.dateFilterType
            ? dateFilterType
            : 'MONTHLY',
      };
    }
    const loadData = appliedFilters?.isDateRangeChanged || isDataReset;
    if (isEmptyOrNull(dataToPost)) return;
    if (isMemberViewOpen) {
      if (isAnalystUser) dataToPost['selectedUserId'] = [loggedInUserId];
      else dataToPost['selectedUserId'] = [userData.results.defaultUser];
      if (isEmpty(props.selectedIdMember)) {
        actions.saveMemberId(dataToPost.selectedUserId);
      }
      if (loadData) {
        actions.saveAppliedFilters({
          ...dataToPost,
          isDateRangeChanged: false,
        });
        actions.fetchMemberData(
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          dataToPost
        );
      } else {
        actions.fetchMemberData(
          isFetchRequired(billabilityTrendsDataMember),
          isFetchRequired(topClientsDataMember),
          isFetchRequired(topDownloadsDataMember),
          isFetchRequired(engagementDepthDataMember),
          isFetchRequired(timeSheetDataMember),
          isFetchRequired(hourlyBillRateData),
          isFetchRequired(cdBillabilityDataMember),
          !isEmptyOrNull(selectedTopProjectsQuestion)
            ? true
            : isFetchRequired(topProjectsDataMember),
          isFetchRequired(rmsSatisfactionDataMember),
          isFetchRequired(knMaterialDataMember),
          dataToPost,
          selectedTopProjectsQuestion
        );
      }
    } else if (isBusinessViewOpen) {
      dataToPost = { ...appliedFilters };
      if (
        (isLoading(props.practiceArea) || isLoading(props.geoMarket)) &&
        !isLineManager
      )
        return;
      if (
        isEmptyOrNull(props.practiceArea) ||
        isEmptyOrNull(props.geoMarket) ||
        isEmptyOrNull(props.defaultUserFilters)
      )
        return;
      if (
        !isSuperAdminRoleNew(loggedInUserRole) &&
        (isEmptyOrNull(appliedFilters?.paIds) ||
          isEmptyOrNull(appliedFilters?.geoRegion))
      ) {
        dataToPost = getPostDataForBusinessView(
          props.practiceArea?.data,
          props.geoMarket?.data,
          dataToPost,
          props.defaultUserFilters?.data,
          isAnalystUser,
          isLineManager
        );
      }
      if (isEmptyOrNull(dataToPost)) return;
      if (
        !isEmpty(appliedCollection) &&
        !isEmpty(appliedCollection?.selectedUserId)
      ) {
        dataToPost.selectedUserId = appliedCollection?.selectedUserId;
      }
      if (!props.isMemberFilterSelected) delete dataToPost.selectedUserId;
      if (
        !props.isMemberFilterSelected &&
        !isEmptyOrNull(props.defaultUserFilters?.data?.selectedUserId)
      ) {
        dataToPost.selectedUserId =
          props.defaultUserFilters?.data?.selectedUserId;
      }
      if (loadData) {
        actions.saveAppliedFilters({
          ...dataToPost,
          isDateRangeChanged: false,
        });
        actions.fetchBusinessData(
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          dataToPost
        );
      } else {
        actions.fetchBusinessData(
          isFetchRequired(billabilityTrendsDataBusiness),
          isFetchRequired(topClientsDataBusiness),
          !isEmptyOrNull(selectedKtQustion)
            ? true
            : isFetchRequired(ktPulseCheckData),
          isFetchRequired(engagementDepthDataBusiness),
          isFetchRequired(teamSizeData),
          isFetchRequired(topDownloadsDataBusiness),
          isFetchRequired(timeSheetDataBusiness),
          !isEmptyOrNull(selectedBillQuestion)
            ? true
            : isFetchRequired(ktBillabilityData),
          isFetchRequired(cdBillabilityDataBusiness),
          !isEmptyOrNull(selectedTopProjectsQuestion)
            ? true
            : isFetchRequired(topProjectsDataBusiness),
          isFetchRequired(rmsSatisfactionDataBusiness),
          isFetchRequired(knMaterialDataBusiness),
          dataToPost,
          selectedKtQustion,
          selectedBillQuestion,
          selectedTopProjectsQuestion
        );
      }
    }
    isDataReset && actions.resetData(false);
  };

  const getKtPulseData = selectedItem => {
    getApiData(selectedItem);
  };

  const getKtBillabilityData = selectedItem => {
    getApiData('', selectedItem);
  };

  const getTopProjectsData = selectedItem => {
    getApiData('', '', selectedItem);
  };

  const getPageData = openView => {
    onDateChange(
      appliedFilters.startDate,
      appliedFilters.endDate,
      appliedFilters.dateFilterType,
      appliedFilters.timePeriodType
    );
    actions.saveSelectedView(openView);
  };

  const onDateChange = (startDate, endDate, dateFilterType, timePeriodType) => {
    let dateAppliedFilters = {
      startDate,
      endDate,
      dateFilterType,
      isDateRangeChanged: true,
      selectedUserId: appliedFilters.selectedUserId,
      timePeriodType,
    };
    actions.saveAppliedFilters(dateAppliedFilters);
  };

  const renderHomePageBody = () => {
    return (
      <Grid className="home_page_main">
        <Grid.Row className="home_page_main_split_row first_row">
          <Grid.Column width={isBusinessViewOpen ? 10 : 9}>
            <KTBillabilityTrend
              data={
                isBusinessViewOpen
                  ? billabilityTrendsDataBusiness
                  : billabilityTrendsDataMember
              }
            />
          </Grid.Column>
          <Grid.Column width={3}>
            <KTBillability
              data={
                isBusinessViewOpen
                  ? cdBillabilityDataBusiness
                  : cdBillabilityDataMember
              }
              legends={CONFIG.CD_BILLABILITY_LEGENDS}
              className="cd_billability"
              heading="CD BILLABILITY"
              isCd={true}
            />
            {isBusinessViewOpen ? (
              <KTTimesheet data={timeSheetDataBusiness} isBusinessView={true} />
            ) : (
              <KTHourlyBillRate data={hourlyBillRateData} />
            )}
          </Grid.Column>
          <Grid.Column width={isBusinessViewOpen ? 3 : 4}>
            {isBusinessViewOpen ? (
              <KTBillability
                data={ktBillabilityData}
                onQuestionChange={getKtBillabilityData}
                selectedQuestion={props.selectedBillabilityQuestion}
                legends={CONFIG.KT_BILLABILITY_KEYS}
                className="kt_billability"
                heading="KT Benchmark"
              />
            ) : (
              <KTTimesheet data={timeSheetDataMember} />
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="home_page_main_split_row">
          <Grid.Column width={4}>
            <KTEngagementDepth
              data={
                isBusinessViewOpen
                  ? engagementDepthDataBusiness
                  : engagementDepthDataMember
              }
            />
          </Grid.Column>
          <Grid.Column width={6}>
            <KTTopClients
              data={
                isBusinessViewOpen
                  ? topProjectsDataBusiness
                  : topProjectsDataMember
              }
              isBusinessPage={true}
              heading="TOP PROJECTS"
              className="top_projects"
              showDropDown={true}
              onQuestionChange={getTopProjectsData}
              selectedQuestion={props.selectedTopProjectsQuestion}
              isTopProject={true}
            />
          </Grid.Column>
          <Grid.Column width={6}>
            <KTTopClients
              data={
                isBusinessViewOpen
                  ? topClientsDataBusiness
                  : topClientsDataMember
              }
              isBusinessPage={isBusinessViewOpen || isAnalystUser}
            />
          </Grid.Column>
        </Grid.Row>
        {isBusinessViewOpen ? (
          <Grid.Row className="home_page_main_split_row">
            {isLineManager ? (
              <Grid.Column width={5}>
                <KTRms data={rmsSatisfactionDataBusiness} isLineManager />
              </Grid.Column>
            ) : (
              <Grid.Column width={8}>
                <KTPulse
                  data={ktPulseCheckData}
                  onQuestionChange={getKtPulseData}
                  selectedQuestion={props.selectedKtPulseQuestion}
                />
              </Grid.Column>
            )}
            <Grid.Column width={isLineManager ? 11 : 8}>
              <KTTeamSize data={teamSizeData} />
            </Grid.Column>
          </Grid.Row>
        ) : null}
        <Grid.Row className="home_page_main_split_row">
          <Grid.Column width={isLineManager ? 8 : 6}>
            <KTTopDownloads
              data={
                isBusinessViewOpen
                  ? topDownloadsDataBusiness
                  : topDownloadsDataMember
              }
              className="top_downloads"
            />
          </Grid.Column>
          <Grid.Column width={isLineManager ? 8 : 5}>
            <KnMaterials
              data={
                isBusinessViewOpen
                  ? knMaterialDataBusiness
                  : knMaterialDataMember
              }
              isLineManager={isLineManager}
            />
          </Grid.Column>
          {!isLineManager && (
            <Grid.Column width={5}>
              <KTRms
                data={
                  isBusinessViewOpen
                    ? rmsSatisfactionDataBusiness
                    : rmsSatisfactionDataMember
                }
              />
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
    );
  };

  return (
    <Grid className="Kt_main_body">
      <Grid.Row className="Kt_main_body_heading">
        <Grid.Column className="Kt_main_body_heading_left" width={8}>
          <div className="heading_value">
            <div className="heading_value_left">
              <div>Welcome back,&nbsp;</div>
              <div>
                {isLoading(userDetails) ? (
                  <LabelWithSkeleton isLoading size="small" showLine />
                ) : (
                  userData.results.firstName
                )}
              </div>
            </div>
          </div>
        </Grid.Column>
      </Grid.Row>
      {isLoading(userDetails) ? (
        <LabelWithSkeleton isLoading size="small" showLine />
      ) : (
        <Grid.Column className="Kt_main_body_tabs" width={16}>
          {isAnalystUser ? (
            <KTAnalystFilters
              userDetails={
                !isEmpty(selectedUserByImpersonator)
                  ? selectedUserByImpersonator
                  : userData.results
              }
              practiceArea={props.practiceArea}
              geoMarket={props.geoMarket}
              onDropDownChange={getPageData}
              isBusinessView={isBusinessViewOpen}
              onDateChange={onDateChange}
              appliedFilters={appliedFilters}
            />
          ) : null}
          {renderHomePageBody()}
        </Grid.Column>
      )}
    </Grid>
  );
};

export default KTHome;
