/* eslint-disable func-names */
import {
  FILTER_DATA_FETCH_SUCCESS,
  FILTER_DATA_FETCH_FAILURE,
  SELECTED_USER_BY_ADMIN,
} from './actionTypes';

import { batch } from 'react-redux';
import { beginAPICall, endAPICall } from './apiCallActions';
import { API_CALL_IDENTIFIERS } from 'redux/constants';
import { fetchGet } from 'utils/KtCommonServices';
import CONFIG from 'config/configProps';
import { isEmptyOrNull } from 'utils';

const MESSAGES = {
  GROUP: 'KTADMINPAGE',
  ERROR: 'Network error, please try again later',
};

/**
 * Action Creator: Seed Data fetch Success
 * @param {*} data data received from API
 * @param {*} apiIdentifier API identifier
 */
function seedDataFetchSuccess(data, apiIdentifier) {
  return {
    type: FILTER_DATA_FETCH_SUCCESS,
    id: apiIdentifier,
    results: data,
  };
}
/**
 * Action Creator: Seed Data Fetch Failure
 * @param {*} error error received from API
 * @param {*} apiIdentifier  API identifier
 */
function seedDataFetchFailure(error, apiIdentifier) {
  return {
    type: FILTER_DATA_FETCH_FAILURE,
    id: apiIdentifier,
    error,
  };
}

/**
 * Method to check if 'error' is being set from ServiceLayer. If yes, dispatch API-FAIL action
 * @param {*} param0 error
 * @param {*} apiIdentifier API Identifier
 * @param {*} dispatch dispatch Ref
 */
function isErroneousResponse({ error }) {
  if (error) {
    throw new Error(`${MESSAGES.ERROR}`);
  }
  return false;
}

async function fetchGetData(dispatch, apiIdentifier, employeeIdHRO) {
  let endpoint;
  const baseUrl = CONFIG.KT_USER_BASE_URL;
  switch (apiIdentifier) {
    case API_CALL_IDENTIFIERS.FETCH_ADMIN_USER_LIST:
      endpoint = CONFIG.PROTOTYPE_API_URLS.ADMIN_USER_LIST;
      if (!isEmptyOrNull(employeeIdHRO))
        endpoint = `${endpoint}?employeeIdHRO=${employeeIdHRO}`;
      break;
    case API_CALL_IDENTIFIERS.FETCH_SELECTED_USER_ROLES_LIST:
      endpoint = CONFIG.PROTOTYPE_API_URLS.SELECTED_USER_ROLES_LIST;
      if (!isEmptyOrNull(employeeIdHRO))
        endpoint = `${endpoint}?employeeIdHRO=${employeeIdHRO}`;
      break;
    default:
      endpoint = '';
  }
  try {
    const response = await fetchGet(endpoint, baseUrl);
    if (!isErroneousResponse(response)) {
      return batch(() => {
        dispatch(seedDataFetchSuccess(response, apiIdentifier));
      });
    }
  } catch (error) {
    batch(() => {
      dispatch(seedDataFetchFailure(error, apiIdentifier));
    });
  } finally {
    dispatch(endAPICall(apiIdentifier));
  }
}

function fetchAdminUserList(fetchUserDetails, employeeIdHRO) {
  const apiIdentifier = API_CALL_IDENTIFIERS.FETCH_ADMIN_USER_LIST;
  return getApiData(fetchUserDetails, apiIdentifier, employeeIdHRO);
}

function fetchSelectedUserRolesList(employeeIdHRO) {
  const apiIdentifier = API_CALL_IDENTIFIERS.FETCH_SELECTED_USER_ROLES_LIST;
  return getApiData(true, apiIdentifier, employeeIdHRO);
}

function getApiData(callApi, apiIdentifier, employeeIdHRO) {
  return async function (dispatch) {
    if (callApi) {
      dispatch(beginAPICall(apiIdentifier));
      fetchGetData(dispatch, apiIdentifier, employeeIdHRO);
    }
  };
}

export function updateSelectedUserByAdmin(employeeIdHRO) {
  return {
    type: SELECTED_USER_BY_ADMIN,
    selectedUserByAdmin: employeeIdHRO,
  };
}

export const adminPageActions = {
  fetchAdminUserList,
  fetchSelectedUserRolesList,
  updateSelectedUserByAdmin,
};
