import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { AuthorizeHoc } from 'components/common/authorizeHOCPt/authorizeHOC';
import { Grid } from 'semantic-ui-react';
import Tab from 'components/common/KtTabs/Tab';
import Tabs from 'components/common/KtTabs/Tabs';
import CONFIG from 'config/configProps';
import KtAdminTable from './KtAdminTable';
import KtUserDetails from 'components/KtUserDetails';
import './index.scss';
import { isAdminUser, isEmptyOrNull, isFetchRequired } from 'utils';
import { isLoading } from 'utils/common';
import KTBillabilityLoader from 'components/common/KTBillabilityLoader';
import { useHistory } from 'react-router-dom';

const KtAdminPage = props => {
  const {
    adminUserList,
    selectedUserByAdmin,
    // practiceAreaList,
    // loggedInUserId,
    selectedUserByImpersonator,
    impersonatedUserDetails,
    loggedInUserRole,
    isDataReset,
  } = props;
  const history = useHistory();
  const [activeIndex, setActiveIndex] = useState(0);
  // const [selectedUser, setSelectedUser] = useState(selectedUserByAdmin);
  const [isUserSelected, setIsUserSelected] = useState(false);

  const handleTabClick = index => {
    setActiveIndex(index);
  };

  useEffect(() => {
    const impersonatedUser = !isEmptyOrNull(selectedUserByImpersonator)
      ? impersonatedUserDetails?.data
      : loggedInUserRole;
    if (isAdminUser(impersonatedUser)) {
      props.actions.fetchAdminUserList(
        isFetchRequired(adminUserList) || isDataReset,
        selectedUserByImpersonator?.employeeIdHRO || ''
      );
    } else {
      history.push({
        pathname: CONFIG.PROTOTYPE_URLS.HOME,
      });
    }
    if (isDataReset) props.actions.resetData(false);
  }, [adminUserList, isDataReset, impersonatedUserDetails]);

  // useEffect(() => {
  //   props.actions.fetchFiltersData(
  //     isFetchRequired(practiceAreaList),
  //     false,
  //     false,
  //     false,
  //     false,
  //     { employeeIdHRO: loggedInUserId }
  //   );
  // }, [practiceAreaList]);

  const showUserDetails = () => {
    setIsUserSelected(true);
  };

  return (
    <Grid className={`Kt_main_body ${isUserSelected ? '' : 'rightPadding20'}`}>
      <Grid.Row className="Kt_main_body_heading">
        <Grid.Column
          className={`Kt_main_body_tabs ${
            isUserSelected ? 'rightPadding20' : ''
          }`}
          width={isUserSelected ? 12 : 16}
        >
          <Tabs className="global-tabs" activeTabIndex={activeIndex}>
            {CONFIG.ADMIN_TABS.map((tab, index) => {
              return (
                <Tab
                  key={tab}
                  tabText={tab}
                  linkClassName="custom-link"
                  tabIndex={index}
                  handleTabClick={index => handleTabClick(index)}
                >
                  <div className="Kt_main_body_content admin_container">
                    {isLoading(adminUserList) ? (
                      <KTBillabilityLoader />
                    ) : (
                      <KtAdminTable
                        adminUserList={adminUserList}
                        showUserDetails={showUserDetails}
                        selectedUserByAdmin={selectedUserByAdmin}
                      />
                    )}
                  </div>
                </Tab>
              );
            })}
          </Tabs>
        </Grid.Column>
        {isUserSelected && (
          <Grid.Column className="Kt_main_body_user_details" width={4}>
            <KtUserDetails userDetails={selectedUserByAdmin} />
          </Grid.Column>
        )}
      </Grid.Row>
    </Grid>
  );
};
export default withRouter(AuthorizeHoc(KtAdminPage));
