import * as React from 'react';
import Checkbox from '../CheckboxLabel';
import Icon from 'components/common/icon/Icon';
import { isEmptyOrNull } from 'utils';

const Option = ({
  option,
  checked,
  partialChecked,
  handleOptionChange,
  expanded,
  canExpand,
  handleExpandClick,
}) => {
  const { label } = option;
  const isSingleAccess =
    !isEmptyOrNull(option?.parent) &&
    isEmptyOrNull(option?.children) &&
    isEmptyOrNull(option?.parentParent);
  return (
    <div
      className={`vertical-option ${
        canExpand || isSingleAccess ? 'marginL0' : 'marginL20'
      }`}
      onClick={handleExpandClick}
    >
      {canExpand ? <Icon name={`angle ${expanded ? 'up' : 'down'}`} /> : null}
      <Checkbox
        labelTxt={label}
        handleChange={handleOptionChange}
        checked={checked}
        partialChecked={partialChecked}
      />
    </div>
  );
};

export default Option;
