import React, { useEffect, useState } from 'react';
import CONFIG from 'config/configProps';
import './styles/index.scss';
import OptionNode from './OptionNode';
import { isEmpty, isEqual } from 'utils/common';
import {
  backwardSelectionChange,
  forwardSelectionChange,
  getLengthOfTreeData,
  getLenghtOfSelectedValue,
} from 'utils/dd';
import { isSuperAdminRoleNew } from 'utils';
import { onFiltersDropDownOpen, onFiltersInteractions } from 'config/analytics';

const ReactTreeDropdown = props => {
  const {
    dropdownOptions,
    filterName,
    userRole,
    defaultUserFilters,
    expandOptions,
  } = props;
  const isSuperAdmin = isSuperAdminRoleNew(userRole);
  const dropdownOptionsLength = getLengthOfTreeData(dropdownOptions);
  const [selectedValues, setSelectedValues] = useState(
    props?.selectedIds || []
  );
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedOnlyValues, setSelectedOnlyValues] = useState({});

  useEffect(() => {
    onFiltersDropDownOpen(`${props.filterText} Filter Opened`);
  }, []);

  useEffect(() => {
    const selectedValuesLength = Object.keys(selectedValues).length;
    if (
      isEqual(selectedValuesLength, dropdownOptionsLength) ||
      selectedValuesLength > dropdownOptionsLength
    ) {
      let isAllSelected = true;
      Object.keys(selectedValues).forEach(value => {
        if (!selectedValues[value].checked) {
          isAllSelected = false;
        }
      });
      setIsAllSelected(isAllSelected);
    }
  }, [setIsAllSelected]);

  const handleAllClick = () => {
    setIsAllSelected(!isAllSelected);
    if (!isAllSelected) {
      let tmpCheckOptionsState = forwardSelectionChange(
        true,
        Object.assign({}, selectedValues),
        'All',
        dropdownOptions
      );
      setSelectedValues(tmpCheckOptionsState);
      handleOptionChange(true, 'All', dropdownOptions);
    } else {
      setSelectedValues({});
      setSelectedOnlyValues({});
    }
  };

  const handleOptionChange = (checked, option, options) => {
    if (!checked) {
      setIsAllSelected(false);
    }
    let tmpCheckOptionsState = forwardSelectionChange(
      checked,
      Object.assign({}, selectedValues),
      option,
      options
    );
    tmpCheckOptionsState = backwardSelectionChange(
      checked,
      tmpCheckOptionsState,
      option,
      options
    );
    setSelectedValues(tmpCheckOptionsState);
    setSelectedOnlyValues(getAllSelectedValues(tmpCheckOptionsState));
  };

  const getAllSelectedValues = selectedValues => {
    let paAll = [],
      topicSectorAll = [],
      subSectorAll = [],
      allWeekPostedDates = [];
    Object.keys(selectedValues).forEach(key => {
      const value = selectedValues[key];
      if (value.checked) {
        switch (value.type) {
          case 'PRACTICE_AREA':
            if (
              (!isSuperAdmin &&
                defaultUserFilters?.paAll?.includes(value.id)) ||
              isSuperAdmin ||
              props.isBusinessComparisonPage
            ) {
              paAll.indexOf(value.id) < 0 && paAll.push(value.id);
            }

            break;
          case 'SECTOR':
            if (
              (!isSuperAdmin &&
                defaultUserFilters?.topicSectorAll?.includes(value.id)) ||
              isSuperAdmin ||
              props.isBusinessComparisonPage
            ) {
              topicSectorAll.indexOf(value.id) < 0 &&
                topicSectorAll.push(value.id);
            }

            if (
              value?.parent &&
              defaultUserFilters?.paAll?.includes(value?.parent.id)
            ) {
              paAll.indexOf(value?.parent.id) < 0 &&
                paAll.push(value?.parent.id);
            }
            break;
          case 'SUB_SECTOR':
            subSectorAll.push(value.id);
            if (
              value?.parent &&
              defaultUserFilters?.topicSectorAll?.includes(value?.parent.id)
            ) {
              topicSectorAll.indexOf(value?.parent.id) < 0 &&
                topicSectorAll.push(value?.parent.id);
            }
            if (
              value?.parentParent &&
              defaultUserFilters?.paAll?.includes(value?.parentParent.id)
            ) {
              paAll.indexOf(value?.parentParent.id) < 0 &&
                paAll.push(value?.parentParent.id);
            }
            break;
          case 'allWeekPostedDates':
            allWeekPostedDates.push(value.id);
            break;
          default:
            break;
        }
      }
    });
    return {
      paAll,
      topicSectorAll,
      subSectorAll,
      allWeekPostedDates,
    };
  };

  const renderOption = (option, index) => {
    return (
      <OptionNode
        checkedOptions={selectedValues}
        key={`verticalParent${index}`}
        option={option}
        parent={undefined}
        handleOptionChange={handleOptionChange}
        expandOptions={expandOptions}
      />
    );
  };

  const applyFilter = () => {
    let weekPostedDates = [],
      homeOffice = [],
      paIds = [],
      topicSectorIds = [],
      subSectorIds = [];
    Object.values(selectedValues).map(value => {
      if (value.checked || value.partialChecked) {
        switch (value.type) {
          case CONFIG.PROTOTYPE_FILTERS.LOCATION.field:
            homeOffice.push(value.id);
            break;
          case CONFIG.PROTOTYPE_FILTERS.WEEK_POSTED_DATE.field:
            weekPostedDates.push(value.id);
            break;
          case 'PRACTICE_AREA':
            paIds.push(value.id);
            break;
          case 'SECTOR':
            topicSectorIds.push(value.id);
            if (value?.parent && paIds.indexOf(value?.parent.id) < 0)
              paIds.push(value?.parent.id);
            break;
          case 'SUB_SECTOR':
            subSectorIds.push(value.id);
            if (value?.parent && topicSectorIds.indexOf(value?.parent.id) < 0)
              topicSectorIds.push(value?.parent.id);
            if (
              value?.parentParent &&
              paIds.indexOf(value?.parentParent.id) < 0
            )
              paIds.push(value?.parentParent.id);
            break;
          default:
            break;
        }
      }
    });
    const finalSelectedNodes = {
      homeOffice,
      weekPostedDates,
      paIds,
      topicSectorIds,
      subSectorIds,
      paAll: selectedOnlyValues?.paAll,
      topicSectorAll: selectedOnlyValues?.topicSectorAll,
      subSectorAll: selectedOnlyValues?.subSectorAll,
      allWeekPostedDates: selectedOnlyValues?.allWeekPostedDates,
    };
    onFiltersInteractions(
      props.filterText,
      `${props.filterText} Filter applied`
    );
    props.applyFilter(
      filterName,
      finalSelectedNodes,
      getLenghtOfSelectedValue(selectedValues),
      '',
      true,
      selectedValues
    );
  };

  const clearFilter = event => {
    setSelectedValues([]);
    setSelectedOnlyValues({});
    if (event) {
      event.stopPropagation();
    }
    props.applyFilter(filterName, {}, 0, '', true, []);
  };
  const disabled =
    isEmpty(selectedValues) ||
    isEqual(
      JSON.stringify(selectedValues),
      JSON.stringify(props.selectedIds)
    ) ||
    props.disableFilter;
  return (
    <div
      className={`treeSelectContainer ${
        props.className ? props.className : ''
      }`}
    >
      <div>
        <div className="react-tree-menu vertical-wrapper">
          <div className="vertical-dropdown">
            {dropdownOptions.length > 1 && (
              <div className="vertical-option">
                <img src="/img/checked_icon.png" />
                <div className="all" onClick={() => handleAllClick()}>
                  {isAllSelected ? 'Deselect All' : 'Select All'}
                </div>
              </div>
            )}
            {dropdownOptions.map(renderOption)}
          </div>
        </div>
      </div>
      <div className="button-footer">
        <div className="clear" onClick={() => clearFilter()}>
          Clear
        </div>
        <div
          className={`apply ${disabled ? 'disabled' : ''}`}
          onClick={e => {
            e.nativeEvent.stopImmediatePropagation();
            applyFilter();
          }}
        >
          Apply
        </div>
      </div>
    </div>
  );
};

export default ReactTreeDropdown;
