import * as React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { makeFirstLetterCap } from 'utils/common';
import { isEmptyOrNull } from 'utils';

export default class KTPieChart extends React.PureComponent {
  getDefaultColorsForLegends = legends => {
    const colors = {};
    const crypto = window.crypto || window.msCrypto;
    let array = new Uint32Array(1);
    legends.forEach(legendKey => {
      colors[legendKey] =
        (this.props.colors || {})[legendKey] ||
        `#${Math.floor(crypto.getRandomValues(array) * 16777215).toString(16)}`;
    });
    return colors;
  };

  render() {
    const legends = this.props.legends || [];
    const colors = this.getDefaultColorsForLegends(legends);
    const chartData = transformRawDataToChart(
      this.props.rawData || {},
      legends,
      colors,
      this.props.targetValue,
      this.props.pieSizeOptions,
      this.props.rawData.totalCount
    );
    return (
      <div className="pie_chart_wrapper_rms">
        <div className="pie_chart">
          {chartData && (
            <HighchartsReact
              allowChartUpdate={false}
              options={chartData}
              highcharts={Highcharts}
              containerProps={{
                style: {
                  height: '100%',
                  width: '100%',
                  position: 'relative',
                },
              }}
            />
          )}
        </div>
        {isEmptyOrNull(this.props.rawData) ? null : (
          <div className="highchart_container_bottom">
            <div className="metric_legends">
              {legends.map(key => (
                <div key={key}>
                  <div className="total_values">{`${getPercentageValue(
                    this.props.rawData[key],
                    this.props.rawData.totalCount
                  )}%`}</div>
                  <div className="metric_legend_item">
                    <div
                      className="legend-icon"
                      style={{ backgroundColor: colors[key] }}
                    />
                    <div className="heading">{makeFirstLetterCap(key)}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }
}

function transformRawDataToChart(
  rawData,
  legends,
  colors,
  targetValue,
  options,
  totalCount
) {
  return {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
      events: {
        load: function () {
          let chart = this,
            x = chart.plotLeft + chart.series[0].center[0],
            y = chart.plotTop + chart.series[0].center[1];
          chart.pieCenter = chart.renderer
            .text(`N=${totalCount}`, x, y)
            .css({
              'text-align': 'center',
              fontSize: '1.8rem',
              fontFamily: 'tahoma',
            })
            .add();
          chart.pieCenter.attr({
            x: x - (totalCount || '0').toString().length * 9,
            y: y + 7,
          });
        },
      },
    },
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
      formatter: function () {
        return '<span><b>N=</b>' + rawData[this.key] + '</span>';
      },
    },
    plotOptions: {
      pie: {
        ...options,
        borderWidth: 2,
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
        },
        showInLegend: false,
        point: {
          events: {
            click: function () {
              const chart = this.series.chart;
              let x = chart.plotLeft + chart.series[0].center[0] - 10,
                y = chart.plotTop + chart.series[0].center[1];
              chart.pieCenter.textSetter(`N=${rawData[this.name]}`);
              chart.pieCenter.css({
                color: this.color,
              });
              chart.pieCenter.attr({
                x: x - ((this && this.y) || '0').toString().length * 9,
                y: y + 7,
              });
              return false;
            },
          },
        },
      },
    },
    series: [
      {
        data: legends.map(legend => ({
          name: legend,
          y: getPercentageValue(rawData[legend.toLowerCase()], targetValue),
          color: colors[legend],
          selected: true,
        })),
        size: '90%',
        innerSize: '90%,',
      },
    ],
  };
}

function getPercentageValue(value, targetValue) {
  if (
    value === 0 ||
    isNaN(value) ||
    isNaN(targetValue) ||
    isEmptyOrNull(value)
  ) {
    return 0;
  } else {
    return Math.round((value / targetValue) * 100);
  }
}
