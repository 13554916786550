import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import KTHeader from 'components/KTHeader';
import KTFilters from 'components/KTFilters';
import CONFIG from 'config/configProps';
import UnknownRoute from 'components/UnknownRoute/UnknownRoute';
import withTitle from 'components/common/pageWithTitle';
import KTHome from 'components/KTHome';
import './PrototypeDesign.scss';
import { Grid } from 'semantic-ui-react';
import { isEmpty, isEqual, isLoading } from 'utils/common';
import { fetchGet } from 'utils/KtCommonServices';
import { isAnalyst, isEmptyOrNull } from 'utils';
import KtTableauLoader from 'components/common/TableauLoader';
import { getStaffId } from 'config/analytics';
import KtSideBar from 'components/common/KtSideBar';
import KtPeoplePage from 'components/KtPeoplePage';
import KtBusinessPage from 'components/KtBusinessPage';
import KtAdmin from 'components/KtAdmin';

const env = CONFIG.ENV;
const PrototypeDesign = props => {
  const {
    isFilterOpen,
    lastUpdatedDate,
    userDetails,
    selectedUserByImpersonator,
    impersonatedUserDetails,
  } = props;

  const [impersonatedUserRole, setImpersonatedUserRole] = useState('');
  const isAdminPage = isEqual(
    window.location.pathname,
    CONFIG.PROTOTYPE_URLS.ADMIN_PAGE
  );
  useEffect(() => {
    if (isEmpty(lastUpdatedDate)) {
      getLastUpdatedDate();
    }
  }, [lastUpdatedDate]);

  useEffect(() => {
    if (
      !isEmpty(selectedUserByImpersonator) &&
      !isLoading(impersonatedUserDetails)
    ) {
      setImpersonatedUserRole(impersonatedUserDetails?.data?.results);
    } else {
      setImpersonatedUserRole('');
    }
  }, [impersonatedUserDetails, selectedUserByImpersonator]);

  useEffect(() => {
    if (!isEmptyOrNull(userDetails?.data?.results)) {
      getStaffId(userDetails?.data?.results.email);
    }
  }, [userDetails]);

  const getLastUpdatedDate = () => {
    const baseUrl = CONFIG.KT_BILLING_API_URL;
    const endpoint = CONFIG.PROTOTYPE_API_URLS.LAST_UPDATED_DATE;
    const {
      actions: { updateLastUpdatedDate },
    } = props;

    fetchGet(endpoint, baseUrl).then(response => {
      updateLastUpdatedDate(response);
    });
  };

  const KtHomeComponent = withTitle({
    component: KTHome,
    title: `${CONFIG.DASHBORAD_NAME} ${env} - Home`,
  });

  const isDataloading = () => {
    if (
      !isEmpty(selectedUserByImpersonator) &&
      isLoading(impersonatedUserDetails)
    ) {
      return true;
    }
  };

  const KtBusinessBillingComponent = withTitle({
    component: KtBusinessPage,
    title: `${CONFIG.DASHBORAD_NAME} ${env} - Billability by business`,
  });

  const KtBillingComponent = withTitle({
    component: KtPeoplePage,
    title: `${CONFIG.DASHBORAD_NAME} ${env} - Billability by team member`,
  });

  const KtTimeSheetComponent = withTitle({
    component: KtPeoplePage,
    title: `${CONFIG.DASHBORAD_NAME} ${env} - Timesheet`,
  });

  const KtChargesComponent = withTitle({
    component: KtPeoplePage,
    title: `${CONFIG.DASHBORAD_NAME} ${env} - Charges/Hours`,
  });

  const KtBillRateComponent = withTitle({
    component: KtPeoplePage,
    title: `${CONFIG.DASHBORAD_NAME} ${env} - Bill Rate`,
  });

  const KtAdminComponent = withTitle({
    component: KtAdmin,
    title: `${CONFIG.DASHBORAD_NAME} ${env} - Admin Console`,
  });

  const renderKtFilters = () => {
    if (
      (!isEmpty(impersonatedUserRole) && isAnalyst(impersonatedUserRole)) ||
      isAdminPage
    )
      return null;
    if (!isAnalyst(props.loggedInUserRole)) {
      return <KTFilters />;
    }
  };

  return (
    <div className="Kt_dashboard">
      <KTHeader />
      {isEmptyOrNull(userDetails) ||
      userDetails.isLoading ||
      isEqual(userDetails.isLoading, 'undefined') ||
      isDataloading() ? (
        <Grid className="Kt_dashboard_body">
          <KtTableauLoader className="pageLoader" isLoading={true} />
        </Grid>
      ) : (
        <Grid className="Kt_dashboard_body">
          <KtSideBar />
          {renderKtFilters()}
          <Grid.Column
            width={13}
            className={`Kt_dashboard_body_middle ${
              isFilterOpen ? '' : 'marginLeft10'
            } ${isAnalyst(props.loggedInUserRole) ? 'analyst_container' : ''} ${
              isAdminPage ? 'admin_main_container' : ''
            }`}
          >
            <Switch>
              <Route
                exact
                path={CONFIG.PROTOTYPE_URLS.HOME}
                component={KtHomeComponent}
              />
              <Route
                exact
                path={CONFIG.PROTOTYPE_URLS.BUSINESS_BILLING}
                component={KtBusinessBillingComponent}
              />
              <Route
                exact
                path={CONFIG.PROTOTYPE_URLS.BILLING}
                component={KtBillingComponent}
              />
              <Route
                exact
                path={CONFIG.PROTOTYPE_URLS.TIMESHEET}
                component={KtTimeSheetComponent}
              />
              <Route
                exact
                path={CONFIG.PROTOTYPE_URLS.CHARGES_HOURS}
                component={KtChargesComponent}
              />
              <Route
                exact
                path={CONFIG.PROTOTYPE_URLS.BILL_RATE}
                component={KtBillRateComponent}
              />
              <Route
                exact
                path={CONFIG.PROTOTYPE_URLS.ADMIN_PAGE}
                component={KtAdminComponent}
              />
              <Route component={UnknownRoute} />
            </Switch>
          </Grid.Column>
        </Grid>
      )}
    </div>
  );
};

export default PrototypeDesign;
