import { connect } from 'react-redux';
import KtAdmin from './KtAdmin';
import { bindActionCreators } from 'redux';
import { selectors } from 'redux/reducers';
import actions from 'redux/actions';
import { API_CALL_IDENTIFIERS } from 'redux/constants';
/**
 * method to create PROPS for seed Data  by fetching them from Store, using selectors
 * @param {*} state Application State
 * @param {*} apiIdentifier API Identifier (State Identifier)
 */
function getPropsForSeedData(state, apiIdentifier, selector) {
  return {
    isLoading: selectors.apiCallStatusSelectors.getApiCallStatus(
      state,
      apiIdentifier
    ),
    data: selectors[selector].getResults(state, apiIdentifier),
  };
}

/**
 * Method to  Format the State and pass them as Props to the component
 * @param {*} state  application State
 */
function mapStateToProps(state) {
  return {
    adminUserList: getPropsForSeedData(
      state,
      API_CALL_IDENTIFIERS.FETCH_ADMIN_USER_LIST,
      'adminSelector'
    ),
    userDetails: selectors.ktHomeSelectors.getResults(
      state,
      API_CALL_IDENTIFIERS.FETCH_USER_DETAILS
    ),
    selectedUserByAdmin: state.adminPageData.selectedUserByAdmin,
    practiceAreaList: getPropsForSeedData(
      state,
      API_CALL_IDENTIFIERS.FETCH_PRACTICE_AREA_LIST,
      'filterSectionSelectorsPt'
    ),
    loggedInUserId: state.homePageData.loggedInUserId,
    selectedUserByImpersonator: state.homePageData.selectedUserByImpersonator,
    impersonatedUserDetails: getPropsForSeedData(
      state,
      API_CALL_IDENTIFIERS.FETCH_IMPERSONATED_USER_DETAILS,
      'filterSectionSelectorsPt'
    ),
    loggedInUserRole: state.homePageData.loggedInUserRole,
    isDataReset: state.homePageData.isResetData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      fetchAdminUserList: bindActionCreators(
        actions.adminPageActions.fetchAdminUserList,
        dispatch
      ),
      updateUserDetails: bindActionCreators(
        actions.homepageActions.updateUserDetails,
        dispatch
      ),
      fetchFiltersData: bindActionCreators(
        actions.protoTypeActions.fetchSeedDataFilters,
        dispatch
      ),
      resetData: bindActionCreators(
        actions.homepageActions.updateIsResetData,
        dispatch
      ),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(KtAdmin);
