import React, { useState } from 'react';
import Radio from 'components/common/CommonHtml/radio';
import moment from 'moment';
import CONFIG from 'config/configProps';
import {
  isAnalyst,
  isEmptyOrNull,
  // isFetchRequired,
  isKtLineManger,
  isSuperAdminRoleNew,
} from 'utils';
import './index.scss';
import { isEmpty, isEqual } from 'utils/common';
// import KtDropdown from 'components/common/KtDropdown';
// import { useSelector } from 'react-redux';

const KtUserDetails = props => {
  const { userDetails } = props;
  // const practiceAreaList = useSelector(state => console.log(state));

  const [selectedRole, setSelectedRole] = useState(userDetails?.biggestRole);

  const isUserGeoManaged = isEqual(
    userDetails?.managementModelTransform,
    'Geo Managed'
  );
  const hideTopic = !isUserGeoManaged && isEmptyOrNull(userDetails?.sectorId);
  const hideSubTopic =
    !isUserGeoManaged && isEmptyOrNull(userDetails?.subSectorId);

  const hideAccessSection = () => {
    const role = userDetails?.biggestRole || '';
    return (
      isSuperAdminRoleNew(role) ||
      isAnalyst(role) ||
      isKtLineManger(role) ||
      isEqual(selectedRole, 'Analyst') ||
      isEqual(selectedRole, 'Line Manager') ||
      isEqual(selectedRole, 'Super Admin')
    );
  };

  const renderDropDown = () => {
    return null;
  };

  const handleRadioChange = name => {
    setSelectedRole(name);
  };

  return (
    <div className="user_details_container">
      <div className="name_section">
        <div className="name">{`${userDetails.lastName} ${userDetails.firstName}`}</div>
        <div className="last_updated_date">
          {`Last updated on ${moment(userDetails.lastUpdatedDate).format(
            CONFIG.DATE_TIME_FORMAT_DISPLAY.WEEKLY
          )}`}
        </div>
      </div>
      <div className="user_details_section">
        <div className="user_details">USER DETAILS</div>
        <div className="user_details_row">
          <div className="user_details_column jobTitle">
            <div className="details_heading">Job Title</div>
            <div className="details_value">{userDetails.jobTitle}</div>
          </div>
          <div className="user_details_column hireDate">
            <div className="details_heading">Hire Date</div>
            <div className="details_value">
              {moment(userDetails.hireDate).format(
                CONFIG.DATE_TIME_FORMAT_DISPLAY.ADMIN_DATE
              )}
            </div>
          </div>
        </div>
        <div className="user_details_row">
          <div className="user_details_column path">
            <div className="details_heading">Path</div>
            <div className="details_value">{userDetails.pathTransform}</div>
          </div>
          <div className="user_details_column model">
            <div className="details_heading">Management Model</div>
            <div className="details_value">
              {userDetails.managementModelTransform}
            </div>
          </div>
          {!isEmptyOrNull(userDetails.geoBusinessTransform) && (
            <div className="user_details_column geo_region">
              <div className="details_heading">Geo Business</div>
              <div className="details_value">
                {userDetails.geoBusinessTransform}
              </div>
            </div>
          )}
        </div>
        <div className="user_details_row">
          <div className="user_details_column region">
            <div className="details_heading">Region</div>
            <div className="details_value">{userDetails.region}</div>
          </div>
          <div className="user_details_column system">
            <div className="details_heading">System</div>
            <div className="details_value">{userDetails.system}</div>
          </div>
          <div className="user_details_column home_office">
            <div className="details_heading">Home Office</div>
            <div className="details_value">{userDetails.homeOffice}</div>
          </div>
        </div>
      </div>
      <div className="user_details_section">
        <div className="user_details">
          {isUserGeoManaged ? 'PA Affiliation' : 'PRACTICE AREA'}
        </div>
        <div className="user_details_row">
          <div className="user_details_column pa">
            <div className="details_heading">PA Affiliation</div>
            {!isEmpty(userDetails.practiceAreaId) ? (
              <div className="details_value">
                {userDetails.practiceAreaName}
              </div>
            ) : (
              renderDropDown()
            )}
          </div>
        </div>
        {!hideTopic && (
          <div className="user_details_row">
            <div className="user_details_column topic">
              <div className="details_heading">Topic/Sector</div>
              {!isEmpty(userDetails.sectorId) ? (
                <div className="details_value">{userDetails.sectorName}</div>
              ) : (
                renderDropDown()
              )}
            </div>
          </div>
        )}
        {!hideSubTopic && (
          <div className="user_details_row">
            <div className="user_details_column subTopic">
              <div className="details_heading">Sub-Topic/Segment</div>
              {!isEmpty(userDetails.subSectorId) ? (
                <div className="details_value">{userDetails.subSectorName}</div>
              ) : (
                renderDropDown()
              )}
            </div>
          </div>
        )}
      </div>
      <div className="user_details_section">
        <div className="user_details">ROLE</div>
        {Object.keys(CONFIG.ADMIN_PAGE_ROLES).map(role => {
          return (
            <div className="roles_display">
              <Radio
                checked={
                  CONFIG.ADMIN_PAGE_ROLES_MAP[role].indexOf(selectedRole) >
                    -1 || isEqual(selectedRole, role)
                }
                value={role}
                name={role}
                handleChange={handleRadioChange}
                bgColor="#c1cad5"
                checkedColor="#4f5b6a"
                className="filter-display__radio"
                showValue={role}
                inputMargin={'0 0.5rem 0 0'}
                roundLeft={'0'}
                circleLeft={'0.3rem'}
              />
              <div className="text">{CONFIG.ADMIN_PAGE_ROLES[role]}</div>
            </div>
          );
        })}
      </div>
      {!hideAccessSection() && (
        <div className="user_details_section">
          <div className="user_details">ACCESS</div>
        </div>
      )}
    </div>
  );
};
export default KtUserDetails;
