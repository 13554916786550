import React from 'react';
import { Grid } from 'semantic-ui-react';
import KTBilling from 'components/KTBilling';
import { useSelector } from 'react-redux';
import { isEmptyOrNull } from 'utils';
import Tab from 'components/common/KtTabs/Tab';
import Tabs from 'components/common/KtTabs/Tabs';

const KtBusinessPage = () => {
  const loggedInUserRole = useSelector(
    state => state.homePageData.loggedInUserRole
  );

  return (
    <Grid className="Kt_main_body">
      <Grid.Row className="Kt_main_body_heading">
        {!isEmptyOrNull(loggedInUserRole) && (
          <Grid.Column className="Kt_main_body_tabs" width={16}>
            <Tabs className="global-tabs" activeTabIndex={0}>
              <Tab
                key="Billability"
                tabText="Billability"
                linkClassName="custom-link"
              >
                <KTBilling />
              </Tab>
            </Tabs>
          </Grid.Column>
        )}
      </Grid.Row>
    </Grid>
  );
};

export default KtBusinessPage;
